import primary_dev from "./environments/primary/dev";
import primary_staging from "./environments/primary/staging";
import primary_prod from "./environments/primary/prod";
import primary_local from "./environments/primary/local";
import replica_dev from "./environments/replica/dev";
import replica_staging from "./environments/replica/staging";
import replica_prod from "./environments/replica/prod";
import replica_local from "./environments/replica/local";

const awsConfig =
  process.env.REACT_APP_REGION === "primary"
    ? process.env.REACT_APP_ENV === "prod"
      ? primary_prod
      : process.env.REACT_APP_ENV === "staging"
        ? primary_staging
        : process.env.REACT_APP_ENV === "dev"
          ? primary_dev
          : primary_local
    : process.env.REACT_APP_ENV === "prod"
      ? replica_prod
      : process.env.REACT_APP_ENV === "staging"
        ? replica_staging
        : process.env.REACT_APP_ENV === "dev"
          ? replica_dev
          : replica_local;

export default awsConfig;
